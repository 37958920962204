<template>
  <div class="px-10">
    <!-- Header -->
    <div class="w-full mb-6 flex flex-col gap-6">
      <div class="w-full flex items-center gap-6">
        <div
          class="w-1/3 text-5xl text-black font-semibold"
          style="flex-shrink: 0"
        >
          SOPIPODBILL DASHBOARD
        </div>
        <div class="w-full flex flex-col gap-6">
          <div class="flex items-center gap-6">
            <div class="w-1/2 flex flex-col gap-2">
              <span>Operating Unit</span>
              <multiselect
                class="selectExample"
                v-model="selectedTerritories"
                :options="optionTerritories"
                :multiple="false"
                :allow-empty="false"
                :group-select="false"
                :max-height="120"
                :limit="3"
                placeholder="Type to search"
                :custom-label="customLabel"
              >
              </multiselect>
            </div>
            <div class="w-1/2 flex flex-col gap-2">
              <span>Territory</span>
              <multiselect
                class="selectExample"
                v-model="selectedTerritories"
                :options="optionTerritories"
                :multiple="false"
                :allow-empty="false"
                :group-select="false"
                :max-height="120"
                :limit="3"
                placeholder="Type to search"
                :custom-label="customLabel"
              >
              </multiselect>
            </div>
          </div>
          <div class="flex items-center gap-6">
            <div class="w-1/2 flex flex-col gap-2">
              <span>Principal / Divisi</span>
              <multiselect
                class="selectExample"
                v-model="selectedTerritories"
                :options="optionTerritories"
                :multiple="false"
                :allow-empty="false"
                :group-select="false"
                :max-height="120"
                :limit="3"
                placeholder="Type to search"
                :custom-label="customLabel"
              >
              </multiselect>
            </div>
            <div class="w-1/2 flex flex-col gap-2">
              <span>Distribution Channel</span>
              <multiselect
                class="selectExample"
                v-model="selectedTerritories"
                :options="optionTerritories"
                :multiple="false"
                :allow-empty="false"
                :group-select="false"
                :max-height="120"
                :limit="3"
                placeholder="Type to search"
                :custom-label="customLabel"
              >
              </multiselect>
            </div>
          </div>
        </div>
      </div>
      <div class="flex items-center gap-6">
        <div class="w-1/3 flex flex-col gap-2" style="flex-shrink: 0">
          <span>Region</span>
          <multiselect
            class="selectExample"
            v-model="selectedTerritories"
            :options="optionTerritories"
            :multiple="false"
            :allow-empty="false"
            :group-select="false"
            :max-height="120"
            :limit="3"
            placeholder="Type to search"
            :custom-label="customLabel"
          >
          </multiselect>
        </div>
        <div class="w-1/3 flex flex-col gap-2">
          <span>Customer Group</span>
          <multiselect
            class="selectExample"
            v-model="selectedTerritories"
            :options="optionTerritories"
            :multiple="false"
            :allow-empty="false"
            :group-select="false"
            :max-height="120"
            :limit="3"
            placeholder="Type to search"
            :custom-label="customLabel"
          >
          </multiselect>
        </div>
        <div class="w-1/3 flex flex-col gap-2">
          <span>Sales Order Type</span>
          <multiselect
            class="selectExample"
            v-model="selectedTerritories"
            :options="optionTerritories"
            :multiple="false"
            :allow-empty="false"
            :group-select="false"
            :max-height="120"
            :limit="3"
            placeholder="Type to search"
            :custom-label="customLabel"
          >
          </multiselect>
        </div>
      </div>
    </div>

    <!-- Content -->
    <div class="flex gap-8">
      <div class="w-3/5">
        <div class="text-xl font-semibold mb-6">Chart SOPIPODBILL</div>
        <BarChart
          :chartData="this.chartDataTodayData"
        />
        <div
          class="flex flex-wrap"
          style="margin-left: -12px; margin-right: -12px"
        >
          <div class="w-1/2" style="padding: 12px">
            <SopipodbillCard
              title="TOTAL SO"
              itemLabel="SO"
              :itemCount="this.soTarget"
              :total="formatPrice(this.data.sales_order)"
              :percentage="this.data.so_target.percentage"
            />
          </div>

          <div class="w-1/2" style="padding: 12px">
            <SopipodbillCard
              title="TOTAL PI"
              itemLabel="PI"
              :itemCount="this.piTarget"
              :total="formatPrice(this.data.performa_invoice)"
              :percentage="this.data.pi_target.percentage"
            />
          </div>

          <div class="w-1/2" style="padding: 12px">
            <SopipodbillCard
              title="TOTAL POD"
              itemLabel="POD"
              :itemCount="this.podTarget"
              :total="formatPrice(this.data.proof_of_delivery)"
              :percentage="this.data.pod_target.percentage"
            />
          </div>

          <div class="w-1/2" style="padding: 12px">
            <SopipodbillCard
              title="TOTAL BILL"
              itemLabel="BILL"
              :itemCount="this.billTarget"
              :total="formatPrice(this.data.billing)"
              :percentage="this.data.billing_target.percentage"
            />
          </div>
        </div>
      </div>
      <div class="w-2/5 flex flex-col gap-6">
        <div>
          <div class="text-xl font-semibold mb-6">Data Versus SO</div>
          <div class="flex flex-col gap-4">
            <DataGoalItem title="%PI vs SO" :total="this.piToSoSo" :dataCount="this.piToSoPi" />
            <DataGoalItem title="%POD vs SO" :total="this.podToSoSo" :dataCount="this.podToSoPod" />
            <DataGoalItem
              title="%Bill vs SO"
              :total="this.billToSoSo"
              :dataCount="this.billToSoBill"
            />
          </div>
        </div>
        <div>
          <div class="text-xl font-semibold mb-6">
            Document Need to Follow Up
          </div>
          <div class="flex flex-col gap-4">
            <DataGoalItem title="%SO to PI" :total="this.piToSoSo" :dataCount="this.piToSoPi" />
            <DataGoalItem title="%SO to POD" :total="this.podToSoSo" :dataCount="this.podToSoPod" />
            <DataGoalItem
              title="%SO to Bill"
              :total="this.billToSoSo"
              :dataCount="this.billToSoBill"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DataGoalItem from '../SoStages/partial/DataGoalItem.vue';
import BarChart from '@/components/charts/BarChart.vue';
import DoughnutChart from '@/components/charts/DoughnutChart.vue';
import SopipodbillCard from './partials/SopipodbillCard.vue';
import { mapState, mapActions } from 'vuex/dist/vuex.common.js';

export default {
  components: {
    DataGoalItem,
    BarChart,
    DoughnutChart,
    SopipodbillCard,
  },
  data() {
    return {
      baseUrl: '/api/report/v1/territories',
      selectedTerritories: [],
      optionTerritories: [],
      territoryIDs: [],
      territoryCodes: [],
      territoryNames: [],
      draw: 0,
      chartDataTodayData: {
        labels: ['Target', 'SO', 'PI', 'POD', 'BIll'],
        datasets: [
          {
            backgroundColor: ['#598edb', '#598edb', '#598edb', '#598edb', '#598edb'],
            data: [],
          },
        ],
      },
      piToSoSo: 0,
      piToSoPi: 0,
      podToSoSo: 0,
      podToSoPod: 0,
      billToSoSo: 0,
      billToSoBill: 0,
      soTarget: 0,
      piTarget: 0,
      podTarget: 0,
      billTarget: 0,
    };
  },
  methods: {
    ...mapActions({ getSopipodbill: 'sopipodbill/getSopipodbill' }),
    customLabel(val) {
      if (val) {
        return val.code === '-' ? `${val.name}` : `(${val.code}) ${val.name}`;
      }
    },
    formatPrice(val) {
      if (isNaN(val)) {
        val = 0;
      }
      return new Intl.NumberFormat().format(val)
    },
  },
  mounted() {
    this.getSopipodbill();
  },
  computed: {
    ...mapState({
      data: (state) => state.sopipodbill.data,
    }),
  },
  watch:{
    data(){
      if (this.data) {
        const list = [this.data.target, this.data.sales_order, this.data.performa_invoice, this.data.proof_of_delivery, this.data.billing]
        this.chartDataTodayData.datasets[0].data = list
        this.data.pi_to_so.map((el)=>{
          if (el.label === 'pi') {
            this.piToSoPi = el.value
          }
          if (el.label === 'so') {
            this.piToSoSo = el.value
          }
        })
        this.data.pod_to_so.map((el)=>{
          if (el.label === 'pod') {
            this.podToSoPod = el.value
          }
          if (el.label === 'so') {
            this.podToSoSo = el.value
          }
        })
        this.data.billing_to_so.map((el)=>{
          if (el.label === 'bill') {
            this.billToSoBill = el.value
          }
          if (el.label === 'so') {
            this.billToSoSo = el.value
          }
        })
        this.soTarget = this.data.so_target.total
        this.piTarget = this.data.pi_target.total
        this.podTarget = this.data.pod_target.total
        this.billTarget = this.data.billing_target.total
      }
    },
  }
};
</script>

<style lang="scss" scoped></style>
