<template>
    <div class="p-6 w-full flex gap-4 justify-between items-center" style="border-radius: 16px;">
        <div class="flex flex-col justify-between gap-4">
            <div class="flex flex-col gap-1">
                <div class="text-2xl font-bold">{{ title }}</div>
                <div class="text-2xl">{{ itemCount + ' ' + itemLabel }}</div>
            </div>
            <div class="flex flex-col gap-1">
                <div class="text-2xl font-bold">Value</div>
                <div class="text-3xl font-semibold text-grey">{{ total }}</div>
            </div>
        </div>
        <DoughnutChart title="vs Target" :value="this.percentage" :total="100" :isHideDetail="true" :percentageStyles="18" />
    </div>
</template>

<script>
import DoughnutChart from '@/components/charts/DoughnutChart.vue';

export default {
    props: {
        title: {
            type: String,
            default: '',
        },
        itemLabel: {
            type: String,
            default: '',
        },
        total: {
            type: Number,
            default: 0,
        },
        itemCount: {
            type: Number,
            default: 0,
        },
        percentage: {
            type: Number,
            default: 0,
        },
    },
    components:{
        DoughnutChart
    }

}
</script>

<style lang="scss" scoped></style>