<template>
  <div>
    <Doughnut
      :chart-options="chartOptions"
      :chart-data="chartData"
      :chart-id="chartId"
      :dataset-id-key="datasetIdKey"
      :plugins="plugins"
      :css-classes="cssClasses"
      :styles="styles"
      :width="width"
      :height="height"
    />
    <div class="donut-inner">
      <div :style="{fontSize: percentageStyles + 'px' }">{{ ((value / total) * 100).toFixed(0) }}%</div>
    </div>
    <div class="text-center">
      <div>{{ title }}</div>
      <div v-if="!isHideDetail" class="font-bold">{{ value + '/' + total }}</div>
    </div>
  </div>
</template>

<script>
import { Doughnut } from 'vue-chartjs/legacy';

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
} from 'chart.js';

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);

export default {
  name: 'DoughnutChart',
  components: {
    Doughnut,
  },
  props: {
    chartId: {
      type: String,
      default: 'doughnut-chart',
    },
    datasetIdKey: {
      type: String,
      default: 'label',
    },
    width: {
      type: Number,
      default: 200,
    },
    height: {
      type: Number,
      default: 150,
    },
    cssClasses: {
      default: '',
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Number,
      default: 0,
    },
    total: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      default: '',
    },
    isHideDetail: {
      type: Boolean,
      default: false
    },
    percentageStyles: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      percentage: ((this.value / this.total) * 100).toFixed(0),
      chartData: {
        datasets: [
          {
            backgroundColor: ['#41B883', '#EDF2F7'],
            data: [
              ((this.value / this.total) * 100).toFixed(0),
              100 - ((this.value / this.total) * 100).toFixed(0),
            ],
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        elements: {
          arc: {
            borderWidth: 0,
          },
        },
        layout: {
          padding: 10,
        },
        rotation: -90,
        circumference: 180,
        cutout: '70%',
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    };
  },
  watch: {
    value(val) {
      this.chartData = {
        datasets: [
          {
            backgroundColor: ['#41B883', '#EDF2F7'],
            data: [
              ((val / this.total) * 100).toFixed(0),
              100 - ((val / this.total) * 100).toFixed(0),
            ],
          },
        ],
      };
    },
    total(val) {
      this.chartData = {
        datasets: [
          {
            backgroundColor: ['#41B883', '#EDF2F7'],
            data: [
              ((this.value / val) * 100).toFixed(0),
              100 - ((this.value / val) * 100).toFixed(0),
            ],
          },
        ],
      };
    },
  },
};
</script>

<style>
.donut-inner {
  margin-top: -57px;
  text-align: center;
  font-size: 24px;
  margin-left: auto;
  margin-right: auto;
}
</style>
