<template>
    <div class="flex flex-col gap-4">
        <div class="flex justify-between">
            <div class="flex flex-col gap-2">
                <div>{{ title }}</div>
                <div class="text-black">{{ getCurrentPercentage(dataCount, total) + "%" }}</div>
            </div>
            <div class="flex flex-col gap-2">
                <div class="flex gap-1 items-center">
                    <div>{{ getPercentage(dataCount, total) + "%" }}</div>
                    <i v-if="getPercentage(dataCount, total) > 0" class="fas font-weight-light text-danger fa-arrow-down"></i>
                    <i v-if="getPercentage(dataCount, total) === 0" class="fas font-weight-light text-success fa-minus"></i>
                </div>
                <div class="text-grey">{{ dataCount + "/" + total }}</div>
            </div>
        </div>
        <div v-if="getCurrentPercentage(dataCount, total) > 0" class="p-1 rounded-full"
            :style="{ width: `${getCurrentPercentage(dataCount, total)}%`, backgroundColor: '#7b68ee' }"></div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: ""
        },
        total: {
            type: Number,
            default: 0
        },
        dataCount: {
            type: Number,
            default: 0
        },
    },
    methods: {
        getCurrentPercentage(data, totalValue) {
            if (data !== 0 && totalValue !== 0) {
                return Math.round((data / totalValue) * 100)
            }
            return 0
        },
        getPercentage(data, totalValue) {
            if (data !== 0 && totalValue !== 0) {
                return 100 - (Math.round((data / totalValue) * 100))
            }
            return 0
        }
    }
}
</script>

<style lang="scss" scoped></style>